import { Component, effect, input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'mars-landing-card',
  standalone: true,
  imports: [],
  templateUrl: './landing-card.component.html',
  styleUrl: './landing-card.component.scss',
})
export class LandingCardComponent {
  svg = input.required<string>();
  title = input<string>();
  description = input<string>();
  points = input<string[]>();
  cardClass = input<string>();

  sanitizedSvg!: SafeHtml;

  constructor(private readonly sanitizer: DomSanitizer) {
    effect(() => {
      const svgInput = this.svg();

      if (svgInput) this.sanitizedSvg = this.sanitizeSvg(this.svg());
    });
  }

  sanitizeSvg(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
