<div
  [class]="cardClass()"
  class="card at-h-100 at-flex-grow at-p-3xl at-bg-neutral-50 at-radius-xxl">
  <div [innerHTML]="sanitizedSvg" class="card-icon at-mb-3xl"></div>

  <div class="card-title at-text-primary-500 at-fw-bold at-mb-sm">
    @if (title()) {
      <p>{{ title() }}</p>
    }
  </div>

  <div class="card-description at-text-neutral-700 at-text-sm">
    @if (description()) {
      <p>
        {{ description() }}
      </p>
    }
  </div>

  <div class="card-points at-text-xs at-text-neutral-700 at-fw-medium at-mt-xl">
    @for (point of points(); track $index) {
      <p>- {{ point }}</p>
    }
  </div>
</div>
